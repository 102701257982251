import React, { useState } from 'react';
import axios from 'axios';

const NewPayment = () => {
  const [isCorpsMember, setIsCorpsMember] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('card'); // Default method

  const handleToggle = () => {
    setIsCorpsMember(!isCorpsMember);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/api/payments/initialize', {
        subscription_id: subscriptionId,
        payment_method: paymentMethod,
        is_corps_member: isCorpsMember,
      });

      if (response.data.status) {
        // Redirect user to the Paystack payment URL
        window.location.href = response.data.authorization_url;
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Payment initialization failed:', error.response.data.message);
    }
  };

  return (
    <div>
      <h1>Make a Payment</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Subscription ID:
          <input
            type="text"
            value={subscriptionId}
            onChange={(e) => setSubscriptionId(e.target.value)}
            required
          />
        </label>
        <br />

        <label>
          Payment Method:
          <select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <option value="card">Card</option>
            <option value="transfer">Transfer</option>
            <option value="bank">Bank</option>
            <option value="ussd">USSD</option>
            <option value="visa_qr">Visa QR</option>
            <option value="wallet">Wallet</option>
          </select>
        </label>
        <br />

        <label>
          Corps Member:
          <input
            type="checkbox"
            checked={isCorpsMember}
            onChange={handleToggle}
          />
        </label>
        <br />

        <button type="submit">Pay Now</button>
      </form>
    </div>
  );
};

export default NewPayment;
